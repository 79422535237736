import React from 'react'

import { HeadlineContainer, Headline } from './styles'

const PromoBanner = () => {
  return (
    <HeadlineContainer>
      <Headline>
        <b>#BundleUpwithSOOD</b> by getting 2 jewelry of your choice, get the
        third one 50% off!
      </Headline>
    </HeadlineContainer>
  )
}

export default PromoBanner
